import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { BackUserType } from "../../types";
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient';

type ResetPasswordProps = {
  backUser: BackUserType;
};

const backUserDefinition = {
  email: 'String',
};

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const {
    backUser,
  } = props;

  const resetPassword = (backUser: BackUserType) => axiosClient.post('/back_users/password', {
    backUser: { ...backUser }
  }).then((res) => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });

  const onSubmit = () => resetPassword(castAttributesFromDefinition(backUserDefinition, object));

  const { object, onChange } = useAntForm(backUser || { 'email': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  const ResetPasswordSchema: AntSchema = [
    {
      name: 'email',
      label: intl.formatMessage({ id: 'words.email' }),
      input: {
        type: 'string',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    }
  ]

  return (
    <Card>
      <AntForm
        schema={ResetPasswordSchema}
        object={object}
        layout="vertical"
        // @ts-ignore
        onChange={onChange}
        onSubmit={onSubmit}
        submitText={intl.formatMessage({ id: 'sentences.reset_password' })}
        errors={errors}
      />
    </Card>
  )
};

export default ResetPassword;