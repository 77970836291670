import { useAntForm } from "@9troisquarts/ant-form";
import { Button, Col, Row, Space } from "antd";
import React from "react";
import { BackUserType } from "../../types";
import post from "../../../utils/httpPost";
import { castAttributesFromDefinition, ModelDefinitionType } from '@9troisquarts/wand'
import useI18n from "../../../utils/useI18n";
import config from '../../../config';
import styled from '@emotion/styled'
import microsoftLogo from '../../../../assets/images/ms-symbollockup_signin_light.svg';

const Layout = styled.div`
  display: block;
  text-align: center;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled.div`
  display: block;
  > img {
    width: 200px;
    margin: 20px 0;
  }
`

type SignInProps = {
  backUser: BackUserType;
  errors: any;
};

const backUserDefinition: ModelDefinitionType = {
  email: 'String',
  password: 'Password',
};

const env = process.env.NODE_ENV || 'development';

type Provider = 'saml' | 'developer'

const redirectToSSO = (provider: Provider) => {
  const providerConfig = config.authentication?.providers[provider].environments[env];
  window.location.href = `${providerConfig.authUrl}`;
};

const SignIn: React.FC<SignInProps> = props => {
  const {
    backUser,
    errors
  } = props;
  const { t } = useI18n();

  const signInbackUser = (backUser: BackUserType) => post('/back_users/sign_in', {
    ...backUser
  }, "back_user")

  const onSubmit = () => {
    signInbackUser(castAttributesFromDefinition(backUserDefinition, object));
  };

  const { object, onChange } = useAntForm(backUser || {});

  return (
    <Layout>
      <Container>
        {config.authentication.logo && (
          <LogoContainer>
            <img src={config.authentication.logo} />
          </LogoContainer>
        )}
        <Space direction="vertical">
          {config.authentication?.providers?.saml?.enable && config.authentication?.providers?.saml?.environments[env]?.authUrl && (
            <Row>
              <Col xs={24}>
                <a onClick={() => redirectToSSO('saml')}>
                  <img src={microsoftLogo} />
                </a>
              </Col>
            </Row>
          )}
          {config.authentication?.providers?.developer?.enable && config.authentication?.providers?.developer?.environments[env]?.authUrl && (
            <Row>
              <Col xs={24}>
                <Button onClick={() => redirectToSSO('developer')}>
                  Sign in as developer
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Container>
    </Layout>
  )
};

export default SignIn;